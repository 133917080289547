.banner {
    display: block;
    width: 100%;
    height: 100vh;
    background-image: url(../images/Shape.png);
    background-size: cover;
    background-position: center;
    padding-top: 20px;
    padding-left: 20px;
}

.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 0 20px;
    padding-bottom: 60px;
}

.nav .logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
}
.nav #nav_logo {
    /* width: 100px; */
    height: 60px;
    margin-top: 20px;
}

.nav .menu {
    /* width: 50%; */
    max-width: 80%;
    height: auto;
    /* width: auto; */
    /* background-color: #0f0; */
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 5px
}
#responsive_nav_threedot{
    width: 30px;
    height: 30px;
    display: none;
}

.nav .menu li {
    padding: 0 15px;
    justify-self: unset;
    /* background-color: #f00; */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav .menu li a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    font-family: sans-serif;
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
}

.nav .menu li a:hover {
    border-bottom: 1px solid #fff;
    transition: 0.5s;
}

#loginButton {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    width: 100px;
    height: 40px;
    text-align: center;
    justify-content: center;
    line-height: 40px;
    border-radius: 20px;
}

#loginButton:hover {
    background-color: #fff;
    color: #000;
    transition: 0.5s;
}

#bookFreeButton {
    background-color: #fff;
    color: #000;
    border: 2px solid #fff;
    width: 150px;
    height: 40px;
    text-align: center;
    justify-content: center;
    line-height: 40px;
    border-radius: 20px;
}

#bookFreeButton:hover {
    background-color: #000;
    color: #fff;
    transition: 0.5s;
}

.banner .responsiveNav{
    display: block;
    margin-left: auto;
    width: 90%;
    height: auto;
    background-color: rgba(255,255,255,1);
    position: absolute;
    top: 0;
    /* right: 0; */
    z-index: 100;
    padding: 20px;
    box-shadow: 0 0 100px rgba(0,0,0,0.3);
}
.banner .responsiveNav .responsiveNavTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}
.banner .responsiveNav .responsiveNavTop #responsiveNavLogo{
    width: 100px;
    
    filter: invert(100%) brightness(0%);
}
.banner .responsiveNav .responsiveNavTop #responsiveNavClose{
    width: 25px;
}
.banner .responsiveNav .responsiveMenu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    list-style: none;
}
.banner .responsiveNav .responsiveMenu li{
    padding: 10px 0;
}
.banner .responsiveNav .responsiveMenu li a{
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: sans-serif;
    text-decoration: none;
}
.banner .responsiveNav .responsiveMenu li a:hover{
    color: #000;
    transition: 0.5s;
}
#responsiveNavBookFreeTrialButton{
    background-color: #000;
    color: #fff;
    border: 2px solid #000;
    width: 150px;
    height: 40px;
    text-align: center;
    justify-content: center;
    line-height: 40px;
    border-radius: 40px;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
}


.banner .textContent {
    display: block;
    width: 55%;
    height: auto;
    padding: 0 20px;
    /* margin-top: 100px; */
}

.banner .textContent .title {
    font-size: 2.5rem;
    font-weight: 800;
    font-size: 64px;
    font-family: sans-serif;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    text-align: left;
}

.banner .textContent .subTitle {
    font-size: 1.5rem;
    font-weight: 800;
    font-size: 36px;
    font-family: sans-serif;
    color: #CBD5E1;
    text-align: left;
    margin-bottom: 20px;
}
#bookFreeTrialButton {   
    background-color: transparent;
    display: block;
    color: #fff;
    border: 2px solid #fff;
    width: 250px;
    /* height: 50px; */
    text-align: center;
    justify-content: center;
    line-height: 40px;
    border-radius: 40px;
    font-size: 30px;
    padding: 10px;
    line-height: 50px;
    text-decoration: none;
    margin-top: 50px;
}

/* =================================================================== */

@media only screen and (max-width: 640px) {
    .banner{
        height: 50vh;
        /* background-image: url(../images/Shape.png); */
        /* background-color: #D2D6DA; */
        background-size: cover;
        background-position: center;
        padding-top: 0px;
        padding-left: 20px;
    }
    .banner .nav{
        display: flex;
        padding-bottom: 10px;
    }
    .banner .menu{
        display: none;
    }
    #responsive_nav_threedot {
        display: block;
        filter: invert(100%) brightness(200%);
        /* The filter inverts the colors, making black white, and then increases brightness to ensure white remains white */
    }
    
    .banner .textContent{
        width: 100%;
        padding: 0 20px;
    }
    .banner .textContent .title{
        font-size: 25px;
        text-align: left;
        width: 100%;
    }
    .banner .textContent .subTitle{
        font-size: 15px;
        text-align: left;
        width: 100%;
    }
    #bookFreeTrialButton {   
        font-size: 16px;
        width: 130px;
        line-height: 15px;
        margin-top: 20px;
    }

}