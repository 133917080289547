body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

h1 {
    text-align: center;
    color: #333;
    margin-top: 20px;
    font-size: 2.5em;
}

.developers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.developer {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.developer:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.developerImage img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
}

.developerInfo {
    padding: 15px;
}

.developerInfo h3 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
}

.developerInfo p {
    margin: 5px 0;
    color: #777;
}

.developerContact {
    background-color: #f5f5f5;
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.developerContact a {
    color: #333;
    font-size: 1.2em;
    text-decoration: none;
}

.developerContact a:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .developer {
        width: 100%;
        max-width: 300px;
    }
}
