* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main {
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/Shape.png');
    background-size: cover;
    display: flex;
    overflow: hidden;
}

.left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left .title {
    font-size: 2.5rem;
    font-weight: 800;
    font-size: 56px;
    font-family: sans-serif;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
}

.left .subTitle {
    font-size: 1.5rem;
    font-weight: 400;
    font-size: 28;
    font-family: sans-serif;
    color: #CBD5E1;
    text-align: center;
    margin-bottom: 20px;
}

.right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #000; */
}

.right .form {
    width: 80%;
    height: 85%;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px 20px 80px 20px;
    border-radius: 5px;
    /* justify-content: center; */
    /* align-items: center; */
}

.form .formGroup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form .formGroup label {
    font-weight: 800;
    font-size: 20px;
    font-family: sans-serif;
    color: #FFF;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.form .formGroup input {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #FFF;
    background: transparent;
    color:#FFF;
}

.form .formGroup input::placeholder {
    color: #CBD5E1;
}

.genderSelecter {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#courses {
    width: 100%;
    height: 40px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #FFF;
    background: transparent;
    color: #FFF;
    font-size: large;
}

#courses::placeholder {
    color: #CBD5E1;
}

#courses option {
    color: #000;
}

#submitButton {
    width: 100%;
    height: 140px;
    background: #FFF;
    color: #000;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 800;
    font-family: sans-serif;
    cursor: pointer;
    margin-top: 50px;
}


@media only screen and (max-width: 640px) {
    .main {
        flex-direction: column;
    }

    .left {
        width: 100%;
        height: 10%;
        display: none;
    }

    .right {
        width: 100%;
        height: 90%;
    }

    .right .form {
        width: 90%;
        height: 100%;
    }
    .radioInput{
        width: 20px;
        height: 20px;
    }
    .form .formGroup input {
        height: 18px;

    }
    


}