
.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 2rem;
    background-color: #F1F5F8;
}

.about .about-title {
    font-size: 4rem;
    /* Adjust font size as needed */
    font-weight: bold;
    /* Optional: Adjust text weight */
    background: linear-gradient(45deg, #CBD5E1, #475569);
    /* Gradient colors */
    -webkit-background-clip: text;
    /* Chrome, Safari */
    -moz-background-clip: text;
    /* Firefox */
    background-clip: text;
    /* Standard syntax */
    color: transparent;
    /* Hide the original text color */
}
.about .about-content {
    width: 80%;
    font-size: 1.5rem;
    /* Adjust font size as needed */
    margin-top: 1rem;
    /* Optional: Adjust margin */
    text-align: center;
    margin-bottom: 2rem;
}
label {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    width: 100%;
    text-align: left;
}